import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';

import Banner from 'components/Banner';
import LinkTree from 'components/LinkTree';
import PageIntroduction from 'components/PageIntroduction';
import { useAllSitePageContext } from 'context/allSitePage';
import { useSiteSettingsContext } from 'context/siteSettings';

import { SitemapPageComponentProps } from './models';
import './SitemapPageMain.scss';

const SitemapPage: FC<SitemapPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoSitemap: {
      banner,
      url,
      excludedPages,
      pageIntroduction,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
    },
  },
}) => (
  <Layout
    {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="sitemap-page"
  >
    {banner.map(({ properties: { title, ...restBannerProperties } }) => (
      <Banner
        key={title}
        {...{ ...restBannerProperties, image: restBannerProperties.localImage, url, title }}
      />
    ))}
    <div className="sitemap-page__wrapper">
      {pageIntroduction ? (
        <PageIntroduction
          className="sitemap-page__introduction"
          pageIntroduction={pageIntroduction}
        />
      ) : null}
      <LinkTreeContainer className="sitemap-page__links-tree" excludedPages={excludedPages} />
    </div>
  </Layout>
);

type Link = React.ComponentProps<typeof LinkTree>['links'][0];
type LinkTreeContainerProps = Pick<
  SitemapPageComponentProps['data']['umbracoSitemap'],
  'excludedPages'
> & {
  className?: string;
};

const LinkTreeContainer: FC<LinkTreeContainerProps> = ({ excludedPages, className }) => {
  const { allSitePages, getPageNameByUrl } = useAllSitePageContext();
  const {
    siteSettings: { lang },
    rootLang,
  } = useSiteSettingsContext();

  const isExcludedPage = useMemo(
    () => (potentiallyExcludedUrl: string) => {
      return [
        '/404/',
        '/404.html',
        '/dev-404-page/',
        '/sitemap/',
        ...excludedPages?.map(({ url: excludedUrl }) =>
          excludedUrl[0] === '/' ? excludedUrl : `/${excludedUrl}`
        ),
      ].some((excludedUrl) => potentiallyExcludedUrl === excludedUrl);
    },
    [excludedPages]
  );

  const isSameLanguagePage = useMemo(
    () => (urlLang: string) => {
      return urlLang === lang;
    },
    [lang]
  );

  const links = useMemo(() => {
    const base = {
      url: 'root',
      name: 'root',
      children: [],
    } as Link;

    allSitePages
      .filter(({ context }) => {
        const isAllowedPage = context?.url ? !isExcludedPage(context.url) : false;
        const isSameLangPage = context?.lang ? isSameLanguagePage(context.lang) : false;

        return isAllowedPage && isSameLangPage;
      })
      .forEach(({ context }) => {
        if (!context) return;

        const { url } = context;

        const regex = new RegExp(/\/[^\\/]+/g);
        const urlParts = url.match(regex) || [];

        if (lang === rootLang) {
          urlParts.unshift('/');
          if (urlParts.length > 1) {
            urlParts[1] = urlParts[1]?.slice(1);
          }
        }

        let curr = base;

        urlParts.forEach((_, i) => {
          let currUrl = urlParts.slice(0, i + 1).join('');
          currUrl = currUrl === '/' ? currUrl : `${currUrl}/`;
          const child = curr.children.find((e) => e.url === currUrl);

          if (child) {
            curr = child;
          } else {
            curr.children.push({
              url: currUrl,
              name: getPageNameByUrl(currUrl),
              children: [],
            });

            curr = curr.children[curr.children.length - 1];
          }
        });
      });

    return base.children;
  }, [allSitePages, getPageNameByUrl, isExcludedPage, isSameLanguagePage]);

  return <LinkTree {...{ links, className }} />;
};

export const query = graphql`
  query SitemapPage($url: String = "", $lang: String) {
    umbracoSitemap(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      pageIntroduction
      excludedPages {
        url
        name
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default SitemapPage;
