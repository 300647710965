import React, { FC } from 'react';
import classNames from 'classnames';

import { LinkTreeProps } from './models';
import './LinkTree.scss';
import './LinkTreeRTL.scss';

const LinkTree: FC<LinkTreeProps> = ({ links, className }) => (
  <div className={classNames('link-tree', className)}>
    {links?.map((link) => (
      <LinkTreeItem key={link.name} links={[link]} />
    ))}
  </div>
);

const LinkTreeItem: FC<Pick<LinkTreeProps, 'links'>> = ({ links }) => (
  <ul>
    {links.map((link) => (
      <li key={link.url}>
        <a href={link.url}>{link.name}</a>
        {link.children.length ? <LinkTreeItem links={link.children} /> : null}
      </li>
    ))}
  </ul>
);

export default LinkTree;
